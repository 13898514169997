import React from 'react';
import {Icon, Path} from '../components';
import {IconProps} from '../types';

export function IconEye({
  width = '16px',
  fill = 'currentColor',
  ...props
}: IconProps) {
  return (
    <Icon width={width} fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.956 8C14.1538 8 16 9.82609 16 12C16 14.1739 14.1538 16 11.956 16C9.75824 16 8 14.1739 8 12C8 9.82609 9.75824 8 11.956 8Z"
        fill={fill}
      />
      <Path
        d="M1.26538 12.8274C0.871203 12.3475 0.872037 11.6543 1.26737 11.1754C4.41406 7.36329 8.22855 5.49995 12.0002 5.5C15.7718 5.50005 19.5862 7.3635 22.7329 11.1757C23.1283 11.6547 23.1291 12.3472 22.735 12.826C19.6466 16.5778 15.8455 18.4711 12.0642 18.4997C8.28519 18.5283 4.44227 16.695 1.26538 12.8274Z"
        stroke={fill}
      />
    </Icon>
  );
}
