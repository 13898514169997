import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';

import {resetPassword} from '../state/user';
import {useAppDispatch} from '../utils';

import {Box, Text, Input, Loader, Container} from 'components';

const errorMessages = {
  invalidLink:
    'The link that you are using has expired. Please ask for a new link.',
  technicalProblem:
    "oops! looks like we're having some difficulties. Please try again later.",
  wrongPasswordFormat:
    'Password must have at least 8 characters, from which at least one is a letter and one is a number',
  noPasswordProvided: 'no password provided',
  passwordDontMatch: "the passwords don't match",
};

const Card = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Button = styled.button`
  border: 0px;
  background: #000000;
  border-radius: 20px;
  margin-top: 30px;
  padding: 7px;
  width: 250px;

  &:hover {
    background: #202020;
  }

  &:active {
    background: #606060;
  }
`;

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/,
      errorMessages.wrongPasswordFormat,
    )
    .required(errorMessages.noPasswordProvided),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    errorMessages.passwordDontMatch,
  ),
});

export function ResetPassword() {
  const {resetCode} = useParams<{resetCode: string}>();
  const [finishPage, setFinishPage] = useState(false);
  const [mainErrorMessage, setMainErrorMessage] = useState('');
  const dispatch = useAppDispatch();
  const [isFirstSubmit, setIsFirstSubmit] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  type FormValues = {
    password: string;
    confirmPassword: string;
  };

  useEffect(() => {
    window.analytics.page('password_reset_screen_viewed');
  }, []);

  const onSubmitForm = async (
    {password}: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const variables = {code: resetCode, password: password};
    setShowLoader(true);
    const result = await dispatch(resetPassword(variables));
    setShowLoader(false);
    if (result.payload.error && result.payload.error === 'technical-error') {
      setMainErrorMessage(errorMessages.technicalProblem);
      return;
    }
    if (result.payload.resetPassword.error === 'invalid-reset-code') {
      setMainErrorMessage(errorMessages.invalidLink);
    } else if (result.payload.resetPassword.error === 'password-format') {
      actions.setFieldError('password', errorMessages.wrongPasswordFormat);
    } else {
      setFinishPage(true);
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{password: '', confirmPassword: ''}}
        onSubmit={onSubmitForm}
        validationSchema={SignupSchema}
        validateOnChange={!isFirstSubmit}
        validateOnBlur={!isFirstSubmit}>
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <Card
            position="relative"
            width={400}
            minHeight={200}
            display="flex"
            flexDirection="column"
            alignItems="center">
            <Text fontSize={24} fontWeight="bold" mt={30}>
              Reset password
            </Text>
            {!finishPage && mainErrorMessage === '' && (
              <Box
                pb={30}
                display="flex"
                flexDirection="column"
                alignItems="center">
                <Input
                  mt={20}
                  name="password"
                  type="password"
                  value={values.password}
                  placeholder="New password"
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                  error={errors.password}
                  touched={touched.password}
                />

                <Input
                  mt={20}
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  placeholder="Confirm password"
                  onChange={handleChange('confirmPassword')}
                  onBlur={handleBlur('confirmPassword')}
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                />

                <Button
                  onClick={() => {
                    setIsFirstSubmit(false);
                    handleSubmit();
                  }}>
                  <Text color="#FFF" fontWeight="bold">
                    RESET
                  </Text>
                </Button>
              </Box>
            )}
            {finishPage && (
              <Box mt={20} px={30}>
                <Text>The password has been successfully changed.</Text>
              </Box>
            )}
            {mainErrorMessage !== '' && (
              <Box mt={20} px={30}>
                <Text>{mainErrorMessage}</Text>
              </Box>
            )}
            <Loader show={showLoader} />
          </Card>
        )}
      </Formik>
    </Container>
  );
}
