import React from 'react';
import {Player} from '@lottiefiles/react-lottie-player';

import {Box} from 'components';
// import * as loaderAnimation from './loader.json';

type LoaderProps = {
  show: boolean;
};

export const Loader = (props: LoaderProps) => {
  const {show} = props;

  if (!show) {
    return <></>;
  }

  return (
    <Box
      position="absolute"
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="#FFF"
      opacity={0.5}
      zindex={1}>
      <Player
        autoplay
        loop
        src="/loader.json"
        style={{height: '100px', width: '100px'}}
      />
    </Box>
  );
};
