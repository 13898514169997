import {createAsyncThunk} from '@reduxjs/toolkit';
import {DocumentNode} from 'graphql';
import {ThunkOptions} from '../store';
import {apolloClient} from './apolloClient';

type ErrorCodes = {
  error: 'network-error' | 'technical-error';
};

export const createMutationThunk = <OutputType, InputType = void>(
  path: string,
  mutation: DocumentNode,
) =>
  createAsyncThunk<OutputType & ErrorCodes, InputType, ThunkOptions>(
    path,
    async (variables, {rejectWithValue}) => {
      try {
        const {data} = await apolloClient.mutate({
          mutation,
          variables,
        });
        if (!data) {
          return rejectWithValue(new Error('no data'));
        }
        return data;
      } catch (e: any) {
        if (e.message === 'Network request failed') {
          return {error: 'network-error'};
        } else {
          return {error: 'technical-error'};
        }
      }
    },
  );

export const createQueryThunk = <OutputType, InputType = void>(
  path: string,
  query: DocumentNode,
) =>
  createAsyncThunk<OutputType & ErrorCodes, InputType, ThunkOptions>(
    path,
    async (variables, {rejectWithValue}) => {
      try {
        const {data} = await apolloClient.query({
          query,
          variables,
        });
        if (!data) {
          return rejectWithValue(new Error('no data'));
        }
        return data;
      } catch (e: any) {
        if (e.message === 'Network request failed') {
          return {error: 'network-error'};
        } else {
          return {error: 'technical-error'};
        }
      }
    },
  );
