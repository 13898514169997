import React, {InputHTMLAttributes, useState} from 'react';
import styled from 'styled-components';
import {space, SpaceProps} from 'styled-system';

import {Box, Text, IconEye} from '../';

const StyledInput = styled.input`
  background: #f4f6f7;
  border: 0;
  border-radius: 88px;
  font-size: 16px;
  height: 30px;
  width: 250px;
  padding-left: 10px;

  &::-webkit-input-placeholder {
    font-size: 12px;
  }

  ${space};
`;

const ClickableIconEye = styled(props => <IconEye {...props} />)`
  cursor: pointer;

  &:hover {
    opacity: 50%;
  }
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, SpaceProps {
  name: string;
  error?: string;
  touched?: boolean;
}

export const Input = (props: InputProps) => {
  const {
    name,
    type,
    value,
    placeholder,
    onChange,
    onBlur,
    error,
    touched,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box position="relative" display="flex" flexDirection="column" {...rest}>
      <StyledInput
        name={name}
        type={showPassword ? 'text' : type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      {type === 'password' && (
        <ClickableIconEye
          position="absolute"
          right={10}
          top={10}
          onClick={toggleShowPassword}
        />
      )}
      {error && touched && (
        <Text color="red" fontSize={12} maxWidth={250} px="10px">
          {error}
        </Text>
      )}
    </Box>
  );
};
