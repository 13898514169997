import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  system,
  variant,
} from 'styled-system';

import {Props, StyledBoxProps} from './types';

const StyledBox = styled.div<StyledBoxProps>`
  ${variant({
    variants: {
      flex: {display: 'flex'},
      box: {display: 'block'},
      grid: {display: 'grid'},
    },
  })}

  ${system({w: {property: 'width', scale: 'size'}})};
  ${system({h: {property: 'height', scale: 'size'}})};
  ${background};
  ${border};
  ${color};
  ${flexbox};
  ${grid};
  ${layout};
  ${position};
  ${space};
`;

export const Box = ({variant = 'box', children, ...boxProps}: Props) => {
  return (
    <StyledBox variant={variant} {...boxProps}>
      {children}
    </StyledBox>
  );
};
