import {createSlice} from '@reduxjs/toolkit';

// Define a type for the slice state
interface UserState {
  requestStatus: 'idle' | 'loading';
  token?: string;
  onboarding?: string;
  username?: string;
  bio?: string;
  imageUrl?: string;
}

// Define the initial state using that type
const initialState: UserState = {
  requestStatus: 'idle',
  token: undefined,
  onboarding: undefined,
  username: undefined,
  bio: undefined,
  imageUrl: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default userSlice.reducer;
