import {
  ResetPassword,
  ResetPasswordVariables,
  RESET_PASSWORD_MUTATION,
} from '../../queries/resetPasswordMutation';
import {createMutationThunk} from '../../utils/createMutationThunk';

export const resetPassword = createMutationThunk<
  ResetPassword,
  ResetPasswordVariables
>('user/resetPassword', RESET_PASSWORD_MUTATION);
