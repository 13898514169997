import React, {useEffect} from 'react';
import {Text, Container} from 'components';

export function OpenApp() {
  useEffect(() => {
    window.location.href = 'yourjuno://';
  }, []);

  return (
    <Container>
      <Text>Loading...</Text>
    </Container>
  );
}
