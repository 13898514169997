import {AnyAction, AsyncThunkAction} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type {RootState} from '../store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): ThunkDispatch => useDispatch();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type ThunkDispatch = <
  ArgumentType extends AsyncThunkAction<any, any, any> | AnyAction,
>(
  asyncThunkAction: ArgumentType,
) => extractFromThunk<ArgumentType>;

// Extract the generic types from the AsyncThunkAction function argument
type extractFromThunk<T> = T extends AsyncThunkAction<
  infer Returned,
  infer ThunkArg,
  any
>
  ? Promise<{
      payload: Returned;
      meta: {
        arg: ThunkArg;
        requestId: string;
        requestStatus: string;
        type: string;
      };
    }>
  : T;
