import styled from 'styled-components';
import {Box} from './Box';

export const Container = styled(Box)`
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #ffa6a0 0%, #ffb89e 100%);
  border: 1px transparent solid;
  display: flex;
  justify-content: center;
  align-items: center;
`;
