import styled from 'styled-components';
import {color, flexbox, layout, space, typography} from 'styled-system';

import {Props} from './types';

export const Text = styled.p.attrs(({variant = 'normal'}: Props) => ({
  variant,
}))<Props>`
  font-family: CircularXXWeb;
  font-size: 16;
  line-height: 1.5em;
  margin: 0;
  outline: none;
  outline-offset: 2px;

  ${flexbox};
  ${color};
  ${layout};
  ${space};
  ${typography};
`;
