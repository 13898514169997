import {gql} from '@apollo/client';
export type {
  ResetPassword,
  ResetPasswordVariables,
} from './__generated__/ResetPassword';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($code: String!, $password: String!) {
    resetPassword(code: $code, password: $password) {
      error
    }
  }
`;
