import React from 'react';
import './App.css';
import {ResetPassword} from './pages/ResetPassword';
import {OpenApp} from './pages/OpenApp';
import {Route, BrowserRouter as Router} from 'react-router-dom';

function App() {
  return (
    <Router>
      <Route path="/resetPassword/:resetCode?" component={ResetPassword} />
      <Route path="/openApp" component={OpenApp} />
    </Router>
  );
}

export default App;
