// import {setContext} from '@apollo/client/link/context';
import {createUploadLink} from 'apollo-upload-client';
import {ApolloClient, InMemoryCache} from '@apollo/client';
// import {store} from '../store';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});
// console.log(process.env.REACT_APP_API_URL);

// const authLink = setContext(async (_, headers) => {
//   const state = store.getState();
//   return {
//     headers: {
//       ...headers,
//       authorization: state.user.token ? `Bearer ${state.user.token}` : '',
//     },
//   };
// });

export const apolloClient = new ApolloClient({
  link: httpLink, //authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
