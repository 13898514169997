// import React from 'react';
import styled from 'styled-components';
import {layout, position, space, system, typography} from 'styled-system';
// import {themeGet} from '@styled-system/theme-get';

// import {Box} from '../Box';
import {IconProps} from './types';

export const fill = system({fill: {property: 'fill', scale: 'colors'}});
export const stroke = system({
  stroke: {property: 'stroke', scale: 'colors'},
});

export const Path = styled.path<{fill?: string; stroke?: string}>`
  transition: fill, stroke 100ms cubic-bezier(0, 0, 1, 1);

  ${fill};
  ${stroke};
  stroke-width: ${props => props.strokeWidth};
  stroke-linecap: ${props => props.strokeLinecap};
`;
export const Text = styled.text`
  ${fill};
  ${typography};
`;
export const G = styled.g`
  ${fill};
`;
export const Rect = styled.rect`
  ${fill};
  ${stroke};
`;
export const Circle = styled.circle`
  ${fill};
  ${stroke};
`;
export const Mask = styled.mask`
  ${fill};
`;

export const Icon = styled.svg.attrs(
  ({xmlns = 'http://www.w3.org/2000/svg'}) => ({xmlns}),
)<IconProps>`
  display: block;
  flex-shrink: 0;

  ${layout};
  ${position};
  ${space};
`;

// const IconComponent = styled(Icon)`
//   height: 50%;
// `;

// const IconBox = styled(Box)`
//   overflow: hidden;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 100%;
//   background-color: ${themeGet('colors.brand.normal')};
// `;

// export function RoundIcon({
//   height = '1rem',
//   viewBox = '0 0 20 20',
//   children,
// }: IconProps) {
//   return (
//     <IconBox size={height}>
//       <IconComponent viewBox={viewBox}>{children}</IconComponent>
//     </IconBox>
//   );
// }
